<template>
  <div>
    <div class="header">
      <img class="img1" src="https://www.d-shang.com/image/xgw/sy/LOGO.png" alt="" />
      <ul>
        <li @click="route('/index')"><a>首页</a></li>
        <li @click="route('/space')"><a href="">空间灵感</a></li>
        <li @click="route('/about')" class="headergydsli">
          <a href="">关于顶上</a>
          <div class="headergyds">
            <ul>
              <li class="hoverli1">企业简介</li>
              <li class="hoverli1">企业文化</li>
              <li class="hoverli1">光辉历程</li>
              <li class="hoverli1">企业荣誉</li>
            </ul>
          </div>
        </li>
        <li @click="route('/news')" class="headergyds2li">
          <a href="">新闻中心</a>
          <div class="headergyds2">
            <ul>
              <li class="hoverli2">品牌资讯</li>
              <li class="hoverli2">活动咨询</li>
              <li class="hoverli2">产品咨询</li>
            </ul>
          </div>
        </li>
        <li @click="route('/join')"><a href="">招商加盟</a></li>
        <li @click="route('/contact')"><a href="">联系我们</a></li>
      </ul>
      <div class="login">
        <img class="img2" src="https://www.d-shang.com/image/xgw/sy/login.png" alt="" />
          <a style="cursor:pointer;" @click="dates()" target="_blank">经销商登录</a>
      </div>
    </div>
    <!-- <div>
        <p class="p1">测试适配问题</p>
    </div> -->
    <div class="block" width="100%;height:100%">
      <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
      <!-- <el-col :span="24"> -->
      <!-- <el-carousel :interval="5000" :height="bannerH+'px'"> -->
      <el-carousel :interval="5000">
        <el-carousel-item v-for="(items, index) in items" :key="index">
          <div
            style="
              color: #000000;
              text-align: center;
              overflow: hidden;
              width: 100%;
            "
          >
            <!-- <img style="width: 100%; height: auto;" class="bannertt" :src="items.image" > -->
            <video
              style="width: 100%; height: auto"
              class="bannertt"
              v-if="items.type === 'video'"
              :src="items.url"
              autoplay
              muted
              loop
            ></video>
            <img
              style="width: 100%; height: auto"
              class="bannertt"
              v-else
              :src="items.url"
              alt="Image"
            />
          </div>

          <!-- <img ref="bannerHeight" :src="items.image" @Load="imgLoad"/> -->
        </el-carousel-item>
      </el-carousel>

      <!-- </el-col> -->
    </div>

    

    <div>
      <div class="sy1">
        <img class="sy1img2" src="https://www.d-shang.com/image/xgw/sy/sy1.gif" alt="" />
      </div>
      <img class="sy1img1" src="https://www.d-shang.com/image/xgw/sy/sy3ss.jpg" alt="" />
    </div>
    <div class="sy2">
      <!-- <img class="sy2img1" src="https://www.d-shang.com/image/xgw/sy/sy4.jpg" alt=""> -->
      <ul>
        <li
          :class="lm1 === true ? 'sy2ulbh' : ' '"
          @mouseenter="handleMouseEnter1"
          style="margin-left: 0"
        >
          <img src="https://www.d-shang.com/image/xgw/sy/sy5.png" alt="" />
        </li>
        <li
          :class="lm2 === true ? 'sy2ulbh' : ' '"
          @mouseenter="handleMouseEnter2"
        >
          <img src="https://www.d-shang.com/image/xgw/sy/sy6.png" alt="" />
        </li>
        <li
          :class="lm3 === true ? 'sy2ulbh' : ' '"
          @mouseenter="handleMouseEnter3"
        >
          <img src="https://www.d-shang.com/image/xgw/sy/sy7.png" alt="" />
        </li>
        <li
          :class="lm4 === true ? 'sy2ulbh' : ' '"
          @mouseenter="handleMouseEnter4"
        >
          <img src="https://www.d-shang.com/image/xgw/sy/sy8.png" alt="" />
        </li>
      </ul>
      <div class="bh">
        <img
          :class="lm1 === true ? ' ' : 'mlbh'"
          src="https://www.d-shang.com/image/xgw/sy/sy10.jpg"
          alt=""
        />
        <img
          :class="lm2 === true ? ' ' : 'mlbh'"
          src="https://www.d-shang.com/image/xgw/sy/sy11.jpg"
          alt=""
        />
        <img
          :class="lm3 === true ? ' ' : 'mlbh'"
          src="https://www.d-shang.com/image/xgw/sy/sy9.jpg"
          alt=""
        />
        <img
          :class="lm4 === true ? ' ' : 'mlbh'"
          src="https://www.d-shang.com/image/xgw/sy/sy12.jpg"
          alt=""
        />
      </div>
    </div>
    
    <img class="swiperimg1" src="https://www.d-shang.com/image/xgw/sy/SI/001.jpg" alt="">
    <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/1.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/1.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/2.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/2.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/3.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/3.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/4.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/4.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/5.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/5.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/6.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/6.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/7.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/7.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
      <div class="swiper-slide"><img src="https://www.d-shang.com/image/xgw/sy/SI/8.jpg" srcset="https://www.d-shang.com/image/xgw/sy/SI/8.jpg" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div></div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>

    <div class="sy3">
      <ul>
        <li class="sy3li">
          <img class="sy3img1" src="https://www.d-shang.com/image/xgw/sy/sy15.jpg" alt="" />
          <img class="sy3img2" src="https://www.d-shang.com/image/xgw/sy/sy15s.jpg" alt="" />
        </li>
        <li class="sy3li">
          <img class="sy3img1" src="https://www.d-shang.com/image/xgw/sy/sy16.jpg" alt="" />
          <img class="sy3img2" src="https://www.d-shang.com/image/xgw/sy/sy16s.jpg" alt="" />
        </li>
        <li class="sy3li">
          <img class="sy3img1" src="https://www.d-shang.com/image/xgw/sy/sy17.jpg" alt="" />
          <img class="sy3img2" src="https://www.d-shang.com/image/xgw/sy/sy17s.jpg" alt="" />
        </li>
        <li class="sy3li">
          <img class="sy3img1" src="https://www.d-shang.com/image/xgw/sy/sy18.jpg" alt="" />
          <img class="sy3img2" src="https://www.d-shang.com/image/xgw/sy/sy18s.jpg" alt="" />
        </li>
      </ul>
    </div>

      
    <div class="sy4">
      <div class="sy4div1">
        <a target="_blank" href="https://9afm2d24nei.720yun.com/vr/d13jkdkfzk7"><img class="sy4div1img" src="https://www.d-shang.com/image/xgw/sy/sy19s.png" alt="" /></a>
      </div>
      <div class="sy4div2">
        <div class="sy4div3">
          <a href="https://v.d-shang.com/video/3df84f9-1919c13e293-0004-87de-b78-4a921.mp4" target="_blank"><img class="sy4div3img" src="https://www.d-shang.com/image/xgw/sy/sy20ss.png" alt="" /></a>
        </div>
        <div class="sy4div4">
          <a target="_blank" href="https://9afm2d24nei.720yun.com/vr/d13jkdkfzk7"><img class="sy4div4img" src="https://www.d-shang.com/image/xgw/sy/sy21s.png" alt="" /></a>
        </div>
      </div>
    </div>
      

    <div class="sy5">
      <div class="sy5div1">
        <h1>最新资讯</h1>
        <span
          ><img
            @click="numBer()"
            class="sy5div1img1"
            src="https://www.d-shang.com/image/xgw/sy/sy22.jpg"
            alt="" />
          <img
            @click="numBer2()"
            class="sy5div1img2"
            src="https://www.d-shang.com/image/xgw/sy/sy23.jpg"
            alt=""
        /></span>
      </div>

      <ul>
        <li @click="content(value.id)" class="sy5li" v-for="(value, index) in list" :key="index">
          <img
            class="sy5liimg"
            :src="'https://www.d-shang.com' + value.thumb"
            alt=""
          />
          <h2 class="sy5h2">{{ value.title }}</h2>
          <h3 class="sy5h3">{{ value.subtitle}}</h3>
          <div class="sy5div2">
            <span class="sy5span1">了解更多</span>
            <img class="sy5span2" src="https://www.d-shang.com/image/xgw/sy/sy24.jpg" alt="" />
          </div>
        </li>
      </ul>
    </div>

    <Footer></Footer>

    <!-- <img :style="{opacity:xchm==1 ? '1' : '0'}" class="xchm" src="../../images/gydss/csss/xchm.jpg" alt=""> -->
    <img v-if="this.xchm" class="xchm" src="https://www.d-shang.com/image/xgw/sy/xchms.jpg" alt="">
  </div>
</template>
      

<script>
import img3 from "../../images/gydss/csss/cs3.jpg";
import img5 from "../../images/gydss/csss/cs4.jpg";
import img6 from "../../images/gydss/csss/cs5s.jpg";
import img8 from "../../images/gydss/csss/cs7.jpg";
import Footer from "@/components/Footer.vue"
// import CreateElement from "@/assets/china-lantern"
const img4 =
  "https://v.d-shang.com/e8c2433855cc438687c913f69c90030d/f9f95f66a02e4a0a9453714a26e2601b-5287d2089db37e62345123a1be272f8b.mp4";
export default {
  name:'Index',
  data() {
    return {
      items: [
        { id: 3, type: "image", url: img3 },
        { id: 4, type: "image", url: img6 },
        { id: 5, type: "image", url: img5 },
        { id: 7, type: "image", url: img8 },
        // { id: 4, type: "video", url: img4 },
      ],
      bannerH: this.setBannerH1,
      Top: "",
      class: "0",
      activeName: "second",
      lm1: true,
      lm2: false,
      lm3: false,
      lm4: false,
      list: [],
      num: 0,
      num2: 4,
      date:'',
      xchm:true
    };
  },
  

  components:{
    Footer
  },

  methods: {   
    dates(){
     this.date=Date.now();
     window.open('https://www.dingshangjc.com:6090?r='+this.date,'_blank');
    },

    content(value) {
      this.$router.push({
        name: "NewsContent",
        query: { id: value},
      });
    },


    route(path){
      this.$router.push({
        path:path
      })
    },

    setBannerH() {
      this.bannerH = document.getElementsByClassName("bannertt")[0].height;
    },
    setBannerH1() {
      var bwidth = document.getElementsByClassName("bannertt")[0].width;
      var bheight = document.getElementsByClassName("bannertt")[0].height;
      var ssss = document.body.clientWidth;
      this.bannerH = ssss * (bheight / bwidth);
    },

    WinDow() {
      this.Top = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(this.Top);
      if (this.Top > 1000) {
        this.class = 1;
        console.log(this.class);
      }
    },

    sx() {
      if (window.location.href.indexOf("#reloaded") == -1) {
        window.location.href = window.location.href + "#reloaded";

        window.location.reload();
      }
    },

    handleMouseEnter1() {
      console.log("测试进入1");
      this.lm2 = false;
      this.lm3 = false;
      this.lm4 = false;
      this.lm1 = true;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
    },
    handleMouseEnter2() {
      console.log("测试进入2");
      this.lm1 = false;
      this.lm3 = false;
      this.lm4 = false;
      this.lm2 = true;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
    },
    handleMouseEnter3() {
      console.log("测试进入3");
      this.lm2 = false;
      this.lm1 = false;
      this.lm4 = false;
      this.lm3 = true;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
    },
    handleMouseEnter4() {
      console.log("测试进入4");
      this.lm2 = false;
      this.lm3 = false;
      this.lm1 = false;
      this.lm4 = true;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
    },

    numBer() {
      console.log(-4);
      if (this.num >= 4) {
        this.num = this.num - 4;
        this.num2 = this.num2 - 4;
      } else {
        this.num = 0;
        this.num2 = 4;
      }
    },

    numBer2() {
      console.log(+4);
      // if(this.num>=44){
      if(this.num>=12){
        this.num=12;
        this.num2=12;
        // this.num=40;
        // this.num2=44;
      }
      this.num = this.num + 4;
      this.num2 = this.num + 4;
      
    },

    getnews(p) {
      this.$axios
        .get(//2268  2
          "https://www.d-shang.com/index.php?app/getnewslist/?cid=2268&p=" +
            p +
            "&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw"
        )
        .then((res) => {
          this.list = res.data.data;
          this.list = this.list.splice(this.num, this.num2);
          console.log(this.list);
        });
    },
  },

  mounted() {
    setTimeout(()=>{
     this.xchm=false;
     console.log('计时器？',this.xchm);
    },5000);

    var swiper = new Swiper('.swiper-container',{
		  autoplay: {
			delay: 5000,  
			disableOnInteraction: false,
		  },
		  speed: 700,
		  allowTouchMove: false,
		  lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 3,
		  },
		  centeredSlides: true,
		  spaceBetween : 50,
		  slidesOffsetBefore: 40,
		  loop: true,
		  slidesPerView : 'auto',
		  on: {
			slideChangeTransitionEnd: function(){
			  this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
			},
			slideChangeTransitionStart: function(){
			  this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
			},
		  },
		  pagination: {
			el: '.swiper-pagination',
			clickable :true,
			renderBullet: function (index, className) {
              return '<div class="' + className + '"><span></span><i></i></div>';
            },
		  },
		  navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		  },
		});
		window.onresize=function(){
		  swiper.update();
		}

    this.sx();
    this.setBannerH1(); //初始值根据图片的缩放比率和屏幕大小来定，不然走马灯开始时候就会默认一个值
    window.addEventListener(
      "resize",
      () => {
        this.setBannerH();
      },
      false
    );

    window.addEventListener("scroll", this.WinDow);

    this.$nextTick(() => {
      this.$refs.navTabs.$refs.nav.$nextTick(() => {
        console.log("$refs.nav", this.$refs.navTabs.$refs.nav.$el);
        // 此时tab的nav才渲染dom 否则拿不到el-tabs__item
        const target = document.getElementsByClassName("el-tabs__item");
        const that = this;
        for (let i = 0; i < target.length; i++) {
          target[i].addEventListener("mouseover", () => {
            that.$refs.navTabs.handleTabClick(1, String(i));
          });
        }
      });
    });

    this.getnews(1);
  },

  watch:{
     num:{
       handler(newValue,oldValue){
        //  console.log(999,newValue,oldValue);
        if(newValue!=oldValue){
          this.getnews();
        }
       }
     }
  }
};
</script>

<style lang="stylus" scoped>
.xchm{
  height:100%;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index: 1000;
}

.swiperimg1{
  width:100%;
  height:232px;
  display:block;
}

.sy5div1 {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

.sy5div1img1 {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 25px;
  cursor: pointer;
}

.sy5div1img2 {
  width: 50px;
  height: 50px;
  display: inline-block;
  cursor: pointer;
}

.sy5div1 h1 {
  font-size: 36px;
  margin-left: 120px;
}

.sy5div1 span {
  font-size: 36px;
  margin-right: 180px;
}

.sy5 ul li {
  width: 357px;
  height: 100%;
  margin: 0;
  padding: 0;
}

.sy5 ul li img {
  width: 356px;
  height: 237px;
  margin: 0;
  padding: 0;
}


.sy5div2 {
  margin-top: 60px;
  overflow: hidden;
  height: 36px;
  background: #ffffff;
  width: 193px;
  margin-left: 15px;
  border-radius: 20px;
}

.sy5div2 .sy5span1 {
  float: left;
  color: #cbad65;
  font-size: 18px;
  display: block;
  line-height: 36px;
  margin-left: 16px;
}

.sy5div2 .sy5span2 {
  display: inline-block;
  float: left;
  width: 29px;
  height: 29px;
  margin-left: 60px;
  margin-top: 4px;
}

.sy5 {
  margin: 0px;
  padding: 0px;
  height: 768px;
  overflow: hidden;
  margin-bottom:100px;
}

.sy5 ul {
  margin: 0;
  padding: 0;
  margin-top: 80px;
  height: 528px;
  display: flex;
  justify-content: space-between;
  padding-left: 120px;
  padding-right: 120px;
  flex-wrap: wrap;
}

.sy3li:hover .sy3img1 {
  display: none;
}

.sy5li:hover {
  background: #cbad65;
}

.sy5li {
  cursor: pointer;
  text-align: left;
}

.sy5li:hover .sy5liimg {
  display: none;
}

.sy5li:hover .sy5div2 {
  background: #ffffff;
  margin-top: 60px;
  overflow: hidden;
  height: 36px;
  background: #ffffff;
  width: 150px;
  margin-left: 50px;
  border-radius: 20px;
}

.sy5li:hover .sy5span2 {
  margin-left: 13px;
}

.sy5h2{
  display: inlin-block;
  font-size: 20px;
  margin-top: 30px;
  padding: 0 35px 0 35px;
  color: #000000;
  font-weight: 550;
  // height:100px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

 .sy5h3{
  margin:0 65px 0 36px;
  padding:0;
  color:#808080;
  font-size:12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sy5li:hover .sy5h2 {
  font-size:22px;
  margin-top: 70px;
  color: #ffffff;
  margin:70px 20px 0 20px;
}

.sy5li:hover .sy5h3 {
  margin:40px 55px 0 55px;
  color: #ffffff;
  font-weight:200;
  font-size:14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
}

.sy5li:hover .sy5div2 {
  margin-top: 120px;
}

.sy4 {
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 745px;
  // width: 1920px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy19.jpg');
  background-size: cover;
  overflow: hidden;
}

.sy4div1 {
  height: 743px;
  width: 960px;
  cursor: pointer;
}

.sy4div2 {
  height: 743px;
  width: 945px;
}

.sy4div3 {
  height: 371px;
  cursor: pointer;
}

.sy4div4 {
  height: 366px;
  margin-top: 5px;
  cursor: pointer;
}

.sy4div1img {
  height: 743px;
  width: 960px;
  display: none;
}

.sy4div3img {
  height: 371px;
  width: 945px;
  display: none;
}

.sy4div4img {
  height: 368px;
  width: 945px;
  display: none;
}

.sy4div1:hover .sy4div1img {
  display: block;
}

.sy4div3:hover .sy4div3img {
  display: block;
}

.sy4div4:hover .sy4div4img {
  display: block;
}

.sy3 ul {
  margin: 0px;
  margin-top: 150px;
  margin-left: 215px;
}

.sy3li {
  height: 132px;
  margin-left: 100px;
}

.sy3img1 {
  height: 120px;
  width: 120px;
  display: block;
}

.sy3img2 {
  height: 120px;
  width: 120px;
  display: none;
}

.sy3 {
  background-size: cover;
  margin: 0px;
  padding: 0px;
  height: 370px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy14.jpg');
  overflow: hidden;
  margin-top:100px;
}

.sy3li:hover .sy3img1 {
  display: none;
}

.sy3li:hover .sy3img2 {
  display: block;
}

.headergyds2 ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds2 ul {
  margin-left: 913px;
}

.headergyds2 {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy13.png');
  display: none;
}

.headergyds2li:hover .headergyds2 {
  display: block;
}

.headergyds ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds ul {
  margin-left: 675px;
}

.headergyds {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy13.png');
  display: none;
}

.headergydsli:hover .headergyds {
  display: block;
}

.sy2ulbh {
  background: #ccad65;
}

.mlbh {
  display: none !important;
}

.sy2 .bh img {
  hieght: 700px;
  width: 100%;
  display: block;
}

.sy2 ul li img {
  width: 116px;
  height: 160px;
  margin-top:24px;
}

.sy2 ul li {
  margin-top: 160px;
  // background: #ccad65;
  width: 177px;
  height: 234px;
  padding: 0px;
  z-index: 100;
  margin-left: 100px;
  display: block;
}

.sy2 ul {
  display: flex;
  justify-content: center;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy4s.jpg');
  background-size: cover;
  margin: 0px;
  padding: 0px;
  height: 383px;
  z-index: 100;
}

.sy1img2 {
  width: 640px;
  height: 190px;
  margin-top: 320px;
  margin-left: 180px;
}

.sy1img1 {
  width: 100%;
  height: 401px;
}

.sy1 {
  overflow: hidden;
  height: 717px;
  text-align: left;
  width: 100%;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy2ss.jpg');
  // background-repeat: repeat;
  background-size: cover;
}

.el-carousel {
  // width: 500px;
  height: 830px;
  overflow:hidden;

  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
  }

  /deep/ .el-carousel__indicators { // 指示器
    left: unset;
    transform: unset;
    right: 48%;
    bottom: -50px;
  }

  /deep/ .el-carousel__button { // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 1;
  }

  /deep/ .is-active .el-carousel__button { // 指示器激活按钮
    background: #cbad65;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}

/* .el-carousel__containe /deep/{
    background: red;
    height:100%;
} */
/* .el-carousel /deep/{
      background: blue;
      height:100%;
  }

.el-carousel__container /deep/{
    height: 100%;
    position: none;
} */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
  margin: 0;
  padding: 0;
}

.hide {
  opacity: 0;
}

a {
  text-decoration: none;
}

.login {
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.login a {
  color: #ffffff;
  display: inline-block;
  line-height: 26px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 18px;
  width: 80px;
  font-size: 14px;
}

ul {
  display: flex;
  margin-right: -200px;
}

ul li {
  list-style-type: none;
  color: #fff;
  padding: 0 50px 0 50px;
  font-size: 15px;
  height: 100px;
  /* background: chartreuse; */
}

ul li a {
  color: #ffffff;
  display: inline-block;
  height: 97px;
  cursor: pointer;
  line-height: 100px;
  // transition:all 250ms linear;
}

li a:hover {
  color: #cbad65;
  border-bottom: 3px solid #cbad65;
}

.hoverli1:hover{
  color:#cbad65;
}

.hoverli2:hover{
  color:#cbad65;
}

.block {
  margin-top: 100px;
}

.header {
  height: 100px;
  width: 100%;
  background: #000000;
  display: flex;
  /* justify-content:space-between; */
  justify-content: space-around;
  /* line-height:100px; */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header .img1 {
  width: 236px;
  height: 35px;
  display: inline-block;
  margin-left: -80px;
}

.header .img2 {
  width: 23px;
  height: 26px;
  margin-top: 11px;
}

.p1 {
  font-size: 100px;
  color: red;
  /* background-image: url("../assets/images/swiper/5.jpg"); */
}
</style>

<style>
    body {
      margin: 0;
    }
	h2{	
	  font-family: -webkit-pictograph;
	  font-size:30px;
	  font-style:italic;
	  text-align:center;
    }
    .swiper-container {
	    padding-bottom:40px;
    }
	.swiper-wrapper{
	}
    .swiper-slide {
	    width:978px;
		transition-timing-function:linear;
    }
	@media only screen and (max-width:1200px){
	    .swiper-slide {
	        width:770px;
        }
	}
	@media only screen and (max-width:980px){
	    .swiper-slide {
	        width:471px;
        }
	}
	@media only screen and (max-height:480px){
	    .swiper-slide {
	        width:471px;
        }
	}
	.swiper-slide img{
		width:100%;
		border-radius: 4px;}
	.swiper-slide .title{
		position:absolute;
		transform:rotate(90deg);
		transform-origin:left top;
		left:-3px;
		font-size:11px;
		color: rgb(102, 102, 102);}	
    .swiper-button-next, .swiper-button-prev{
		width:86px;
		height:112px;
		background-size:86px 112px;
		margin-top:-56px;
		outline:none;}
	.swiper-button-next{
		background-image:url('../../public/swiper/img/cursor-next.png')
    }
	.swiper-button-prev{
		background-image:url('../../public/swiper/img/cursor-prev.png')}		
	.swiper-pagination-bullet{
		background:none;
		opacity:1;
		margin:0 6px !important;
		width:9px;
		height:9px;
		position:relative;
		outline:none;
		vertical-align:middle;}
	.swiper-pagination-bullet span{
		width:3px;
		height:3px;
		background:#CCC;
		display:block;
		border-radius:50%;
		margin-top:3px;
		margin-left:3px;
	}
	.swiper-pagination-bullet i{
		background:#000;
		height:1px;
		width:20px;
		position:absolute;
		top:4px;
		transform:scaleX(0);
		transform-origin:left;
		z-index:3;
		transition-timing-function:linear;
		}
	.swiper-pagination-bullet-active span,.swiper-pagination-bullet:hover span{
		width:9px;
		height:9px;
		margin-top:0;
		margin-left:0;
		background:#000;
		position:relative;
		z-index:1;
		}
	.swiper-pagination-bullet-active i{
		animation:middle 6s;
		}
	.swiper-pagination-bullet:first-child.swiper-pagination-bullet-active i{
		animation:first 6s;
		}
	.swiper-pagination-bullet:last-child.swiper-pagination-bullet-active i{
		animation:last 6s;
		}				
	@keyframes first{
	  0% {transform:scaleX(0.5);left:0px;}/*091*/
	  100% {transform:scaleX(1);left:2px;} /*0915*/
	}
	@keyframes last{
	  0% {transform:scaleX(0.7);left:-10px;}/*1090*/
	  20% {transform:scaleX(0.3);left:2px;} /*090*/
	  100% {transform:scaleX(0.3);left:0px;} /*090*/
	}		
	@keyframes middle{
	  0% {transform:scaleX(0.7);left:-10px;}/*1091*/
	  20% {transform:scaleX(0.45);left:2px;}/*092*/
	  100% {transform:scaleX(1);left:2px;} /*0913*/
	}	
  </style>